<script lang="ts">
  import NewsletterSubscriptionForm from "./NewsletterSubscriptionForm.svelte";
  import Modal from "../common/Modal.svelte";
  import { newsletterSubscriptionStore as store } from "../stores";
  import { showToast } from "../toast/toast";
</script>

{#if $store.modalOpen}
  <Modal
    --modal-min-width="0"
    --modal-min-height="0"
    flyOut={$store.submitted}
    title="Subscribe to our Newsletter"
    onclose={() => ($store = { modalOpen: false, submitted: false })}
  >
    <NewsletterSubscriptionForm
      oncancel={() => ($store = { modalOpen: false, submitted: false })}
      onsubmitted={() => {
        $store = { modalOpen: true, submitted: true };
        setTimeout(() => {
          $store = { modalOpen: false, submitted: true };
          showToast("You have successfully subscribed to our newsletter.");
        }, 500);
      }}
    />
  </Modal>
{/if}
